import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getSingle, update } from "../segments.service";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { getConvertedFileName } from "utilities/utility.service";

const initialVales = {
    id: "",
    name: "",
    description: "",
    fileName: "",
    videoUrl: "",
};

const AddSegment = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const [modules, setModules] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [filteredChapters, setFilteredChapters] = useState([]);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchCurrentRecord = async () => {
            setIsLoading(true);
            const record = await getSingle(id);
            setValues(record);
            setIsLoading(false);
        };

        if (id) {
            fetchCurrentRecord();
        } else {
            setValues({ ...values, uniqueNumber: Date.now() });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let formData = new FormData();
        if (!!values.files) {
            const convertedFileName = getConvertedFileName(values?.fileName);
            formData.append("fileName", `${convertedFileName}`);
            formData.append("file", values?.files, `${convertedFileName}`);
            delete values?.files;
            delete values?.fileName;
        }
        for (const key of Object.keys(values)) {
            formData.append(key, `${values[key]}`);
        }

        if (id) {
            const updatedRecord = await update(id, formData);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                setIsLoading(false);
                showError(updatedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Record updated successfully");
                history.push("/segments");
            }
        } else {
            const addedRecord = await add(formData);
            if (addedRecord.status === false) {
                setIsLoading(false);
                showError(addedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Record added succssfully");
                history.push("/segments");
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const multipleUploader = (event, name) => {
        // if (event.files.length > 4) {
        //     // event.files.preventDefault();
        //     showError("You can upload only 4 Files");
        //     alert("You can upload only 4 Files");
        //     event.options.clear();
        // } else {
        //     setValues({
        //         ...values,
        //         associated_image: event.files,
        //     });
        // }
        setValues({
            ...values,
            [name]: event.files[0],
            [`fileName`]: event.files[0].name,
        });
    };

    const videoUploader = (event, name) => {
        setValues({
            ...values,
            [name]: event.files[0],
            [`${name}Name`]: event.files[0].name,
        });
    };

    const statuses = statusList();
    const { name, status, description, videoUrl } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/segments">
                    <Button label="Back" className="p-button-sm p-button" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit Segment" : "Add Segment"}</h5>
                            <div className="field">
                                <label htmlFor="name">Name*</label>
                                <InputText id="name" name="name" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Segment Name" value={name} />
                            </div>
                            <div className="field">
                                <label htmlFor="description">Description*</label>
                                <InputTextarea id="description" name="description" required={true} onChange={(e) => handleInputChange(e)} placeholder="Segment Description" value={description} />
                            </div>

                            <div className="field">
                                <label htmlFor="videoUrl">Video Url</label>
                                <InputText id="videoUrl" name="videoUrl" type="text" required={false} onChange={(e) => handleInputChange(e)} placeholder="Video Url" value={videoUrl} />
                            </div>

                            <div className="field">
                                <label htmlFor="status">Status*</label>
                                <Dropdown id="status" name="status" options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                            </div>

                            <div className="field">
                                <label htmlFor="associated_image">PDF File</label>
                                {/* <InputText id="associated_image" name="associated_image" type="text" onChange={(e) => handleInputChange(e)} placeholder="Associated Image" value={values?.associated_image} /> */}
                                {/* <FileUpload name="assoicated_image" customUpload uploadHandler={(event) => multipleUploader(event)} auto accept="pdf/*" /> */}
                                <FileUpload
                                    name="assoicated_image"
                                    className="fileUpload"
                                    mode="basic"
                                    customUpload
                                    uploadHandler={(event) => multipleUploader(event, "files")}
                                    // onUpload={(event) =>
                                    //     myUploader(event, "profileImage")
                                    // }
                                    auto
                                    chooseLabel="Choose PDF Document"
                                    accept="pdf/*"
                                />
                                <label className="ml-4 mt-2">
                                    {values?.id ? <strong>{values?.id && <a target="_blank" href={`${process.env.REACT_APP_API_BASE_URL}/fetchSegmentsPDF/${values?.fileName}`}>{values?.fileName}</a> }</strong> : <strong>{values?.fileName}</strong>}
                                </label>
                            </div>
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddSegment;
