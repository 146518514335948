import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userInformation: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        training_completed: null,
        assessment_cleared: null,
    },
};

const loggedInUserSlice = createSlice({
    name: "loggedInUser",
    initialState,
    reducers: {
        storeLoggedInformation: (state, action) => {
            state.userInformation = action.payload;
        },
    },
});

const { actions } = loggedInUserSlice;

export const { storeLoggedInformation } = actions;

export const getLoggedInUserInformation = (state) => state.loggedInUser.userInformation;

export default loggedInUserSlice.reducer;
