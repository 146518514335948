import { MOCK_USERS } from "mocks/mock";
import { getAllRoles } from "pages/Roles/roles.service";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { deleteUser, getAllUsers } from "./users.service";

const Users = () => {
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [userId, setUserId] = useState("");

    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const tableHeader = (
        <div className="table-header">
            List of Users
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const fullNameTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <span>{data?.firstName + " " + data?.lastName}</span>
            </>
        );
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${data.status}`}>{data.status}</span>
            </>
        );
    };

    const handleConfirmation = async () => {
        const isDeleted = await deleteUser(userId);
        if (isDeleted) {
            const findDeletedIndex = users.findIndex((user) => user.id === userId);
            users.splice(findDeletedIndex, 1);
            setUserId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                <span className={`customer-badge edit`}>
                    <Link to={`/users/edit/${data.id}`}>Edit</Link>
                </span>
                <span className={`customer-badge edit`}>
                    {/* <Link to="/formlayout">Delete</Link> */}
                    <Button
                        label="Delete"
                        onClick={() => {
                            setDisplayConfirmation(true);
                            setUserId(data.id);
                        }}
                        className="p-button-link"
                    />
                </span>
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchUsers = async () => {
            let userList = await getAllUsers();
            setUsers(userList);
            setIsLoading(false);
        };
        fetchUsers();
    }, []);
    return (
        <Fragment>
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <Link to="/users/add-new">
                            <Button label="Add New" className="mb-3" />
                        </Link>
                        {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                        <DataTable
                            value={users}
                            scrollable
                            scrollHeight="600px"
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            rows={10}
                            dataKey="id"
                            paginator
                            rowHover
                            globalFilter={globalFilter}
                            emptyMessage="No users found."
                            loading={isLoading}
                            header={tableHeader}
                            scrollDirection="both"
                        >
                           <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="fullName" header="Name" sortable></Column>
                           <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="email" header="Email" sortable body={bodyTemplate}></Column>
                           <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                           <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="" header="Actions" sortable body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>

                {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
            </div>
        </Fragment>
    );
};

export default Users;
