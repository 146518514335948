import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllUsers = async () => {
    const result = await restClient.get(`${BASE_URL}/portalusers`, []);
    console.log(result);
    return result?.data;
};

export const getUser = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/portalusers/${payload}`);
    return result?.data;
};

export const addUser = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/portalusers`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const updateUser = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/portalusers/${id}`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const deleteUser = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/portalusers/${id}`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getUsersByRole = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/portalusers`, payload);
    return result?.data;
};
