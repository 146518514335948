import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllUsers = async () => {
    const result = await restClient.get(`${BASE_URL}/users`, []);
    return result?.data;
};


export const getDashboardRecords = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/reports/dashboardRecords`, payload);
    return result?.data;
};
