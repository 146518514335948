import React, { Fragment, useState } from "react";
import { getReportsList } from "./reports.service";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

const Reports = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [reportList, setReportList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleReportClick = async (type) => {
        setIsLoading(true);
        const records = await getReportsList(type);
        setIsLoading(false);
        if (records.length > 0) {
            setReportList(records);
        } else {
            window.alert("No Records Found");
            setReportList([]);
        }
    };

    const tableHeader = (
        <div className="table-header">
            All List
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <span style={{ textTransform: "capitalize" }}>{data[props.field]}</span>
            </>
        );
    };

    return (
        <Fragment>
            <div className="grid mb-4">
                {isLoading && (
                    <div className="spinner inner-spinner">
                        <ProgressSpinner />
                    </div>
                )}
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0" style={{ cursor: "pointer" }} onClick={() => handleReportClick("solution")}>
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-900 font-medium mb-3">Solutions Report</span>
                                <span className="text-500">Click to generate report</span>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-map-marker text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4" style={{ cursor: "pointer" }} onClick={() => handleReportClick("segment")}>
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-900 font-medium mb-3">Segments Report</span>
                                <span className="text-500">Click to generate report</span>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-inbox text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {reportList && reportList.length > 0 && (
                <div className="grid table-demo">
                    <div className="col-12">
                        <div className="card">
                            <DataTable
                                value={reportList}
                                scrollable
                                scrollHeight="600px"
                                className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                rows={10}
                                dataKey="id"
                                paginator
                                rowHover
                                globalFilter={globalFilter}
                                emptyMessage="No records found."
                                header={tableHeader}
                                scrollDirection="both"
                            >
                                <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="userInfo.name" header="Name" sortable></Column>
                                <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="userInfo.company_name" header="Company Name" sortable></Column>
                                <Column style={{ flexGrow: 1, flexBasis: "400px" }} field="type_name" header="Downloaded" sortable body={bodyTemplate}></Column>
                                <Column style={{ flexGrow: 1, flexBasis: "300px" }} field="userInfo.email" header="Email" sortable></Column>
                                <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="userInfo.mobile_number" header="Mobile" sortable></Column>
                                <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="type" header="Type" sortable body={bodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Reports;
