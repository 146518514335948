import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { SuccessToastConfig, ErrorToastConfig } from "utils/ToastConstants";
import { Toast } from "primereact/toast";
import { getRoleByName } from "pages/Roles/roles.service";
import { addUser } from "pages/Users/users.service";
import { Dialog } from "primereact/dialog";
import { verifyOTP } from "./auth.service";

const initialVales = {
    id: "",
    firstName: "",
    middleName: "",
    mobileNumber: "",
    lastName: "",
    password: "",
    email: "",
    dob: "",
    role_id: "",
    pancard: "",
    aadhar: "",
    status: "Pending",
    otp: "",
};

const Signup = () => {
    const history = useHistory();
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchRole = async () => {
            const fetchedRole = await getRoleByName({ name: id });
            if (fetchedRole.length > 0) {
                setValues({
                    ...values,
                    role_id: fetchedRole[0]?.id,
                });
            } else {
                showError("You cannot register as role has been disabled by Admin");
            }
        };

        fetchRole();
    }, []);

    const verifyOtp = async () => {
        if (values?.otp === "") {
            alert("Please enter OTP");
            return;
        }
        if (values?.otp.length !== 6) {
            alert("Please enter 6 digits OTP");
            return;
        }
        setIsLoading(true);
        const payload = { mobileNumber: values?.mobileNumber, otp: values?.otp };
        const record = await verifyOTP(payload);
        if (record && record.status === true) {
            setDisplayConfirmation(false);
            setIsLoading(false);
            showSuccess("OTP Verified Successfully");
            setTimeout(() => {
                history.push("/");
            }, 500);
        } else {
            showError("Incorrect OTP entered. Please enter valid OTP");
            setIsLoading(false);
        }
    };

    const ConfirmationDialogBox = () => {
        const confirmationDialogFooter = (
            <>
                <Button type="button" label="Verify OTP" icon="pi pi-check" onClick={verifyOtp} className="p-button" autoFocus />
            </>
        );
        return (
            <Dialog closeOnEscape={false} header="OTP Verification" visible={displayConfirmation} style={{ width: "350px" }} modal footer={confirmationDialogFooter} className="ConfirmOTP">
                <div className="">
                    {/* <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} /> */}
                    <div className="field">
                        <InputText id="otp" name="otp" maxLength={6} required={true} onChange={(e) => handleInputChange(e)} placeholder="Enter OTP" value={values?.otp} />
                    </div>
                </div>
            </Dialog>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = values;
        const response = await addUser(payload);
        if (response?.status === false) {
            setIsLoading(false);
            showError(response.message);

            // window.location = "/dashboard";
        } else {
            setIsLoading(false);
            setDisplayConfirmation(true);
            // showSuccess("Registration Successful");
            // setTimeout(() => {
            //     history.push("/");
            // }, 500);
        }
    };
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="card p-fluid">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <h5 style={{ textTransform: "capitalize" }}>Register as {id}</h5>
                    <div className="field">
                        <label htmlFor="firstName">First Name*</label>
                        <InputText id="firstName" name="firstName" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Firstname" />
                    </div>
                    <div className="field">
                        <label htmlFor="middleName">Middle Name</label>
                        <InputText id="middleName" name="middleName" type="text" onChange={(e) => handleInputChange(e)} placeholder="Middlename" />
                    </div>
                    <div className="field">
                        <label htmlFor="lastName">Last Name*</label>
                        <InputText id="lastName" name="lastName" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Last Name" />
                    </div>
                    <div className="field">
                        <label htmlFor="mobileNumber">Mobile*</label>
                        <InputText id="mobileNumber" name="mobileNumber" type="text" maxLength={10} required={true} onChange={(e) => handleInputChange(e)} placeholder="Mobile Number" value={values?.mobileNumber} />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email*</label>
                        <InputText id="email" name="email" type="email" required={true} onChange={(e) => handleInputChange(e)} placeholder="Email" />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <InputText type="password" name="password" id="password" required={true} onChange={(e) => handleInputChange(e)} placeholder="Password" />
                    </div>
                    {(id === "processor" || id === "reporter") && (
                        <div className="flex">
                            <Button label="Generate OTP" disabled={!values?.role_id} />
                            <Button
                                type="button"
                                onClick={() => {
                                    history.push("/");
                                }}
                                className="ml-5 p-button-link"
                                label="If already registered, please Login"
                            />
                        </div>
                    )}
                </form>
            </div>
            {displayConfirmation && ConfirmationDialogBox()}
        </Fragment>
    );
};

export default Signup;
