import { getAllRoles } from "pages/Roles/roles.service";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { addUser, getUser, updateUser } from "../users.service";

const initialVales = {
    id: "",
    firstname: "",
    lastname: "",
    password: "",
    email: "",
    role_id: "",
    status: "",
};

const AddUser = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            const record = await getUser(id);
            setValues(record);
            setIsLoading(false);
        };
        if (id) {
            fetchUser();
        }

        const fetchRoles = async () => {
            const roles = await getAllRoles();
            setRoles(roles);
        };
        fetchRoles();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            const updatedRecord = await updateUser(id, {...values, role_id: 1});
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Record updated succssfully");
                history.push("/users");
            }
        } else {
            const addedRecord = await addUser({...values, role_id: 1});
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Record added succssfully");
                history.push("/users");
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const statuses = statusList();
    const { firstname, lastname, status, email, mobileNumber } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/users">
                    <Button label="Back" className="p-button-sm p-button" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit User" : "Add User"}</h5>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="firstname">First Name*</label>
                                        <InputText id="firstname" name="firstname" type="text" required={true} onChange={(e) => handleInputChange(e)} value={firstname} placeholder="Firstname" />
                                    </div>
                                </div>

                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="lastname">Last Name*</label>
                                        <InputText id="lastname" name="lastname" type="text" required={true} onChange={(e) => handleInputChange(e)} value={lastname} placeholder="Last Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="email">Email*</label>
                                        <InputText id="email" name="email" type="email" required={true} onChange={(e) => handleInputChange(e)} value={email} placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="password">Password</label>
                                        <InputText type="password" name="password" id="password" required={id ? false : true} onChange={(e) => handleInputChange(e)} placeholder="Password" />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                <div className="field">
                                        <label htmlFor="role">Status*</label>
                                        <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <div style={{ width: "50%", float: "left"}}>
                                        <Button label="Save" type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddUser;
