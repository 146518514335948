import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { deleteRecord, getAllSegments } from "./segments.service";
import { qrLinkTemplate } from "utilities/utility.service";
import { saveAs } from "file-saver";

const Segments = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [currentId, setCurrentId] = useState("");
    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };
    const tableHeader = (
        <div className="table-header">
            List of Segments
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${data.status}`}>{data.status}</span>
            </>
        );
    };

    const fileTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">File</span>
                <span className={`chapter-${data.fileName}`}>
                    <a target="_blank" href={`${process.env.REACT_APP_API_BASE_URL}/fetchSegmentsPDF/${data?.fileName}`}>
                        {data?.fileName}
                    </a>
                </span>
            </>
        );
    };

    const videoUrlTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Video Url</span>
                <span className={`chapter-${data.videoUrl}`}>
                    <a target="_blank" href={data?.videoUrl}>
                        {data?.videoUrl}
                    </a>
                </span>
            </>
        );
    };

    const handleQRDownloadModal = (qrCodeData, name) => {
        var cert_link = encodeURIComponent(qrCodeData);
        var qr = "https://chart.googleapis.com/chart?chs=350x350&cht=qr&chl="+cert_link+"";
        saveAs(qr, `${name}.jpg`);
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                <span className={`customer-badge edit`}>
                    <Link to={`/segments/edit/${data.id}`}>Edit</Link>
                </span>
                <span className={`customer-badge edit`}>
                    <Button
                        label="Download QR"
                        onClick={() => {
                            handleQRDownloadModal(qrLinkTemplate(data, "segment"), data?.name);
                        }}
                        className="p-button-link"
                    />
                </span>
                <span className={`customer-badge edit`}>
                    <Button
                        label="Delete"
                        onClick={() => {
                            setDisplayConfirmation(true);
                            setCurrentId(data.id);
                        }}
                        className="p-button-link"
                    />
                </span>
            </>
        );
    };

    const dateTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {moment(data[props.field]).format("DD-MM-YYYY HH:MM A")}
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchAllRecords = async () => {
            const allRecords = await getAllSegments();
            setList(allRecords);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchAllRecords();
    }, []);



    const handleConfirmation = async () => {
        const isDeleted = await deleteRecord(currentId);
        if (isDeleted) {
            const findDeletedIndex = list.findIndex((record) => record.id === currentId);
            list.splice(findDeletedIndex, 1);
            setCurrentId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };
    return (
        <div className="grid table-demo">
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="col-12">
                <div className="card">
                    <Link to="/segments/add-new">
                        <Button label="Add New" className="mb-3" />
                    </Link>
                    {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                    <DataTable
                        value={list}
                        scrollable
                        scrollHeight="600px"
                        className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        rows={10}
                        dataKey="id"
                        paginator
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No records found."
                        loading={isLoading}
                        header={tableHeader}
                        scrollDirection="both"
                    >
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="name" header="Name" sortable body={bodyTemplate}></Column>

                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="videoUrl" header="Video Url" sortable body={videoUrlTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="file" header="File" sortable body={fileTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "300px" }} field="status" header="Actions" sortable body={actionBodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="createdAt" header="Created At" sortable body={dateTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
        </div>
    );
};

export default Segments;
