import { MOCK_USERS } from "mocks/mock";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getAllUsers } from "./exhibitors.service";
import moment from "moment";
import { exportToExcel } from "utilities/utility.service";



const Exhibitors = () => {
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");

    const toast = useRef();
   

    const tableHeader = (
        <div className="table-header">
            List of Visitors
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );


    useEffect(() => {
        setIsLoading(true);
        const fetchUsers = async () => {
            let userList = await getAllUsers();
            setUsers(userList);
            setIsLoading(false);
        };
        fetchUsers();
    }, []);

    const cols = [
        { field: "name", header: "Name", sortable: true, style: { flexGrow: 1, flexBasis: "300px" } },
        { field: "company_name", header: "Company Name", sortable: true, style: { flexGrow: 1, flexBasis: "300px" } },
        { field: "email", header: "Email", sortable: true, style: { flexGrow: 1, flexBasis: "300px" } },
        { field: "mobile_number", header: "Mobile", sortable: true, style: { flexGrow: 1, flexBasis: "300px" } },
        {
            field: "createdAt",
            header: "Registered On",
            body: (rowData) => {
                return !!rowData?.createdAt ? moment(rowData.createdAt).format("DD-MM-YYYY HH:MM A") : "-";
            },
            sortable: true,
            style: { flexGrow: 1, flexBasis: "300px" },
            type: "DATE",
        },
        { field: "allValues", header: "All Values", style: { flexGrow: 1, flexBasis: "300px" } },
    ];

    return (
        <Fragment>
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <Button label="Export" onClick={() => exportToExcel(cols, users, "visitor")} className="mb-3" />

                        {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                        <DataTable
                            value={users}
                            scrollable
                            scrollHeight="600px"
                            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                            rows={10}
                            dataKey="id"
                            paginator
                            rowHover
                            globalFilter={globalFilter}
                            emptyMessage="No users found."
                            loading={isLoading}
                            header={tableHeader}
                            scrollDirection="both"
                        >
                            {cols.map((col, index) => (
                                <Column key={index} field={col.field} header={col.header} style={col.style} body={col.body} />
                            ))}
                            {/* <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="company_name" header="Company Name" sortable></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "300px" }} field="name" header="Name" sortable></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "400px" }} field="email" header="Email" sortable body={bodyTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="mobile_number" header="Mobile Number" sortable body={bodyTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="createdAt" header="Registered On" sortable body={dateTemplate}></Column>
                            <Column style={{ flexGrow: 1, flexBasis: "600px" }} field="allValues" header="Scanned Values" sortable body={bodyTemplate}></Column> */}
                        </DataTable>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Exhibitors;
