import moment from "moment";
import { React } from "react";
export const statusList = () => {
    return [
        {
            status: "Active",
        },
        {
            status: "InActive",
        },
    ];
};

export const getConvertedFileName = (fileName) => {
    if (fileName) {
        const splitFileName = fileName.replace(/\s/g, "-").toLowerCase().split(".");
        const newSplitFileName = `${splitFileName[0]}${Date.now()}.${splitFileName[splitFileName.length - 1]}`;
        return newSplitFileName;
    }
};

export const batchStatusList = () => {
    return [
        {
            status: "Acceptance Pending",
        },
        {
            status: "Accepted",
        },
        {
            status: "Processed",
        },
        {
            status: "Completed",
        },
        {
            status: "Approval Pending",
        },
        {
            status: "Approved",
        },
        {
            status: "Closed",
        },
        {
            status: "Rejected",
        },
    ];
};

export const payoutStatusList = () => {
    return [
        {
            status: "Requested",
        },
        {
            status: "Declined",
        },
        {
            status: "Success",
        },
    ];
};

export const getExtension = (fileName) => {
    if (fileName) {
        const splittedFileName = fileName.split(".");
        return splittedFileName[1];
    }
};

export const convertAssessmentToRequired = async (quizQuestions) => {
    let newObject = [];
    if (quizQuestions && quizQuestions.length > 0) {
        quizQuestions.map((element, index) => {
            if (element.question_type === "Regular") {
                newObject.push({
                    question: element?.question_text,
                    questionType: element?.question_type,
                    answers: [
                        {
                            content: element?.option1,
                            type: "option1",
                        },
                        {
                            content: element?.option2,
                            type: "option2",
                        },
                    ],
                    correctAnswer: element?.answer_key,
                    associatedImages: element?.associated_image ? element?.optionsPath?.associated : [],
                });
                if (element?.option3) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.option3, type: "option3" });
                    }
                }
                if (element?.option4) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.option4, type: "option4" });
                    }
                }
                // return newObject;
            } else {
                newObject.push({
                    question: element?.question_text,
                    questionType: element?.question_type,
                    answers: [
                        {
                            content: element?.optionsPath?.option1,
                            type: "option1",
                        },
                        {
                            content: element?.optionsPath?.option1,
                            type: "option2",
                        },
                    ],
                    correctAnswer: element?.answer_key,
                    associatedImages: element?.associated_image ? element?.optionsPath?.associated : [],
                });
                if (element?.option3) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.optionsPath?.option3, type: "option3" });
                    }
                }
                if (element?.option4) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.optionsPath?.option4, type: "option4" });
                    }
                }
            }
        });
    }
    return newObject;
};

export const qrLinkTemplate = (data, type) => {
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    return `${sessionInformation?.qrLink}/?qrType=${type}&slug=${data.slug}`;
};

const getDescendantProps = (obj, desc) => {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
};

export const getFormattedExcelList = (column, list) => {
    let result = [];
    list.forEach((item) => {
        let obj = {};
        column.forEach((node) => {
            if (node.header && node.field) {
                obj[node.header] = node.type === "DATE" ? (!!getDescendantProps(item, node.field) ? moment(getDescendantProps(item, node.field)).format("DD-MM-YYYY HH:MM A") : getDescendantProps(item, node.field)) : getDescendantProps(item, node.field);
            }
        });
        result.push(obj);
    });
    return result;
};

export const exportToExcel = (columns, rowList, fileName) => {
    let data = getFormattedExcelList(columns, rowList);
    import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        saveAsExcelFile(excelBuffer, fileName);
    });
};

const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });

            module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};
