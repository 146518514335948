import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getReportsList = async (type) => {
    const result = await restClient.get(`${BASE_URL}/reports?type=${type}`, []);
    return result?.data;
};
