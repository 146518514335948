import React, { useState, useEffect, Fragment } from "react";
import { getDashboardRecords } from "pages/Exhibitors/exhibitors.service";

const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
        {
            label: "First Dataset",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
        },
        {
            label: "Second Dataset",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
        },
    ],
};

export const Dashboard = () => {
    const [dashboardRecords, setDashboardRecords] = useState(null);

    useEffect(async () => {
        const records = await getDashboardRecords();
        setDashboardRecords(records?.records);
    }, []);
    
    return (
        <Fragment>
            <div className="grid mb-4">
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Segments Downloaded</span>
                                <div className="text-900 font-medium text-xl">{dashboardRecords?.segmentDownloaded}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-shopping-cart text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Solutions Downloaded</span>
                                <div className="text-900 font-medium text-xl">{dashboardRecords?.solutionDownloaded}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-map-marker text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Visitors</span>
                                <div className="text-900 font-medium text-xl">{dashboardRecords?.totalMembers}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-inbox text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Segments</span>
                                <div className="text-900 font-medium text-xl">{dashboardRecords?.totalSegments}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-map-marker text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Solutions</span>
                                <div className="text-900 font-medium text-xl">{dashboardRecords?.totalSolutions}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-brand-color border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-comment text-brand-color text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
