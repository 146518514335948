import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <div className="">
                <img style={{height: "40px"}} src="assets/layout/images/Millenium_Logo-nobg.png" alt="Logo" />

            </div>
            <div>
                Crafted at <a target="_blank" href="https://interosys.com">Interosys</a>
            </div>
            {/* <img src={props.layoutColorMode === "light" ? "assets/layout/images/logo-dark.svg" : "assets/layout/images/logo-white.svg"} alt="Logo" height="20" className="mr-2" />
            by
            <span className="font-medium ml-2">U4U</span> */}
        </div>
    );
};
