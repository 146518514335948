import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAllRoles = async () => {
    const result = await restClient.get(`${BASE_URL}/roles`, []);
    return result?.data;
};

export const addRole = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/roles/create`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const updateRole = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/roles/${id}`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const deleteRole = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/roles/${id}`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getRole = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/roles/${payload}`);
    return result?.data;
};

export const getRoleByName = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/roles/`, payload);
    return result?.data;
};
